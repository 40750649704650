import React from 'react'
import { TableRow, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { parseDateString, redirectToFullMarket } from '../../../../../helpers/common';
import { useHistory } from "react-router-dom";

const EventRow = (props) => {
    const history = useHistory();
    let display = 'grid', mobileDisplay = 'flex';

    const useStyles = makeStyles((theme) => ({

        match: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '6px',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        mobile: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                width: '100%',
                padding: '4px'
            }
        },
        matchStatus: {
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: '53% 47%',
            width: '100%',
            margin: '2px 0'
        },
        Mname: {
            fontWeight: 'bold',
            fontSize: 'var(--large_font)',
            textAlign: 'left',
            color: '#167ac6',
            [theme.breakpoints.down('sm')]: {
                fontSize: 'var(--small_font)',
            }
        },
        startTime: {
            fontSize: 'var(--very_small_font)',
            textAlign: 'right',
            color: '#448595'
        },
        status: {
            color: 'rgb(8, 150, 8)',
            fontSize: '0.65em',
            fontWeight: '700',
            textAlign: 'left'
        },
        cupRate: {
            fontSize: '0.7em',
            fontWeight: '700',
            textAlign: 'left'
        },
        row: {
            cursor: 'pointer',
            display: display,
            width: '100%',
            gridTemplateColumns: '46% 18% 18% 18%',
            [theme.breakpoints.down('sm')]: {
                display: mobileDisplay,
                alignItems: 'center',
                justifyContent: 'space-between'
            }
        },
        noBorder: {
            borderBottom: 'none'
        },
        cell: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        tableCell: {
            padding: '4px',
            [theme.breakpoints.down('sm')]: {
                padding: '0 2px 4px 0',
            }
        },
        matchSchedule: {
            padding: '4px',
            minWidth: '64px',
            [theme.breakpoints.down('sm')]: {
                padding: '0 2px 4px 0',
            }
        },
        dateAndTime: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                backgroundColor: 'var(--sea_green)',
                borderRadius: '6px',
                color: '#fff',
                fontSize: '12px',
                padding: '2px 10px 0',
                fontWeight: 600,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginLeft: '2px'
            }
        },
        time: {
            fontSize: '10px',
            paddingBottom: '2px'
        },
        blue: {
            width: '56px',
            height: '40px',
            backgroundColor: 'var(--odds_blue)',
            margin: '0 1px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 'var(--medium_font)',
            animation: 'fadeIn 2s',
            borderRadius: '6px',
            [theme.breakpoints.down('sm')]: {
                width: '45px',
                height: '35px',
                fontWeight: '700',
                fontSize: 'var(--small_font)',
            }
        },
        pink: {
            width: '56px',
            height: '40px',
            backgroundColor: 'var(--odds_pink)',
            margin: '0 1px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 'var(--medium_font)',
            borderRadius: '6px',
            [theme.breakpoints.down('sm')]: {
                width: '45px',
                height: '35px',
                fontWeight: '700',
                fontSize: 'var(--small_font)',
            },
        },
        hideInDesktop: {
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        }
    }))

    const classes = useStyles();

    const eventDate = new Date(props.marketStartTime);
    const dateTime = parseDateString(props.marketStartTime)
    let marketOdds = (
        <>
            <TableRow className={classes.row} onClick={() => redirectToFullMarket(history, props.matchId)}>
                <TableCell className={[classes.mobile, classes.noBorder].join(' ')} >
                    <div
                        className={classes.Mname}
                    >{props.name}</div>
                    <div className={classes.matchStatus}>
                        {props.matchId === props.cupRate ? <div className={classes.cupRate}><i className="fas fa-trophy" />Cup Rate</div> : props.inplay ? <div className={classes.status}>In-Play</div> : <div></div>}
                    </div>
                </TableCell>
            </TableRow>
            <TableRow className={classes.row} onClick={() => redirectToFullMarket(history, props.matchId)}>
                <TableCell className={classes.match}>
                    <div
                        className={classes.Mname}
                    >{props.name}</div>
                    {props.matchId === props.cupRate ? <div className={classes.startTime}><i className="fas fa-trophy" />Cup Rate</div> : props.inplay ? <div className={classes.status}>In-Play</div> : <div className={classes.startTime}>{eventDate.toLocaleString('en-IN')}</div>}
                </TableCell>
                <TableCell className={[classes.matchSchedule, classes.hideInDesktop].join(' ')}>
                    <div className={classes.dateAndTime}>
                        <div>{dateTime.date}</div>
                        <div className={classes.time}>{dateTime.time}</div>
                    </div>
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <div className={classes.cell}>
                        <div className={classes.blue}>-</div>
                        <div className={classes.pink}>-</div>
                    </div>
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <div className={classes.cell}>
                        <div className={classes.blue}>-</div>
                        <div className={classes.pink}>-</div>
                    </div>
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <div className={classes.cell}>
                        <div className={classes.blue}>-</div>
                        <div className={classes.pink}>-</div>
                    </div>
                </TableCell>
            </TableRow>
        </>
    );
    if (props.odds) {
        marketOdds = (
            <>
                <TableRow className={classes.row} onClick={() => redirectToFullMarket(history, props.matchId)}>
                    <TableCell className={[classes.mobile, classes.noBorder].join(' ')} >
                        <div
                            className={classes.Mname}
                        >{props.name}</div>
                        <div className={classes.matchStatus}>
                            {props.matchId === props.cupRate ? <div className={classes.cupRate}><i className="fas fa-trophy" />Cup Rate</div> : props.inplay ? <div className={classes.status}>In-Play</div> : <div></div>}
                            {/* <div className={classes.startTime}>{eventDate.toLocaleString('en-IN')}</div> */}
                        </div>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.row} onClick={() => redirectToFullMarket(history, props.matchId)}>
                    <TableCell className={classes.match}>
                        <div
                            className={classes.Mname}
                        >{props.name}</div>
                        {props.matchId === props.cupRate ? <div className={classes.startTime}><i className="fas fa-trophy" />Cup Rate</div> : props.inplay ? <div className={classes.status}>In-Play</div> : <div className={classes.startTime}>{eventDate.toLocaleString('en-IN')}</div>}
                    </TableCell>
                    <TableCell className={[classes.matchSchedule, classes.hideInDesktop].join(' ')}>
                        <div className={classes.dateAndTime}>
                            <div>{dateTime.date}</div>
                            <div className={classes.time}>{dateTime.time}</div>
                        </div>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <div className={classes.cell}>
                            <div className={classes.blue}>{props.odds.runners && props.odds.runners.length && props.odds.runners[0].ex.availableToBack && props.odds.runners[0].ex.availableToBack.length && props.odds.runners[0].ex.availableToBack[0].price ? props.odds.runners[0].ex.availableToBack[0].price : '-'}</div>
                            <div className={classes.pink}>{props.odds.runners && props.odds.runners.length && props.odds.runners[0].ex.availableToLay && props.odds.runners[0].ex.availableToLay.length && props.odds.runners[0].ex.availableToLay[0].price ? props.odds.runners[0].ex.availableToLay[0].price : '-'}</div>
                        </div>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <div className={classes.cell}>
                            <div className={classes.blue}>{props.noOfRunners === 2 ? '-' : (props.odds.runners && props.odds.runners.length && props.odds.runners[2].ex.availableToBack && props.odds.runners[2].ex.availableToBack.length && props.odds.runners[2].ex.availableToBack[0].price ? props.odds.runners[2].ex.availableToBack[0].price : '-')}</div>
                            <div className={classes.pink}>{props.noOfRunners === 2 ? '-' : (props.odds.runners && props.odds.runners.length && props.odds.runners[2].ex.availableToLay && props.odds.runners[2].ex.availableToLay.length && props.odds.runners[2].ex.availableToLay[0].price ? props.odds.runners[2].ex.availableToLay[0].price : '-')}</div>
                        </div>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <div className={classes.cell}>
                            <div className={classes.blue}>{props.odds.runners && props.odds.runners.length && props.odds.runners[1].ex.availableToBack && props.odds.runners[1].ex.availableToBack.length && props.odds.runners[1].ex.availableToBack[0].price ? props.odds.runners[1].ex.availableToBack[0].price : '-'}</div>
                            <div className={classes.pink}>{props.odds.runners && props.odds.runners.length && props.odds.runners[1].ex.availableToLay && props.odds.runners[1].ex.availableToLay.length && props.odds.runners[1].ex.availableToLay[0].price ? props.odds.runners[1].ex.availableToLay[0].price : '-'}</div>
                        </div>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    return (
        <>
            {marketOdds}
        </>
    )
}
export default EventRow