import React, { useEffect, useState} from "react";
import axios from "../../../../../axios-instance/backendAPI";
import Fancy from './FancyTable/FancyTable';
import { Table, TableCell, TableHead, TableBody, TableRow, Paper, TableContainer, Button, FormControl, Input, InputLabel } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Modal from '../../../../UI/Modal/Modal'
import BetSpinner from '../../../../UI/Spinner/BetSpinner'
import { useSelector } from "react-redux";
import { forwardRef } from "react";
import MarketTableNew from "./MarketTable/MarkeTableNew";
import { formatCoins, reloadPage } from "../../../../../helpers/common";
import ScoreCard from "./ScoreCard";

const Market = forwardRef((props,ref) => {

    const params = useParams()
    const socket = props.socket
    const [max, setMax] = useState(100)
    const [markets, setMarkets] = useState(null)
    const [marketInfoForAnalysis, setMarketInfoForAnalysis] = useState({})
    const [matchInfo, setMatchInfo] = useState([])
    const [activeId, setActiveId] = useState(null)
    const [book, setBook] = useState(null)
    const [marketIdForMaxUpdate, setMarketIdForMaxUpdate] = useState(null)
    const [open, setOpen] = useState(false)
    const [accept, setAccept] = useState(0)
    const [stakes, setStakes] = useState(null)
    const [code, setCode] = useState('0')
    const [isOnline, setIsOnline] = useState(navigator.onLine)
    const [inplay, setInplay] = useState(false)
    const usertype = useSelector(state => state.auth.usertype)
    const token = useSelector(state => state.auth.token)
    const isBetting = useSelector(state => state.update.isBetting)

    const updateBook = (runners, market_id) => {

        setMarkets(prevValue=>{
            return prevValue.map(market=>{
                if(market.id===market_id)
                    market.runners = runners;
                return market;
            })
        })

    }

    const toggleAcceptAnyOdds = () => {
        if (token) {

            axios.put('/user/toggleAcceptAnyOdds', {}, { headers: { Authorization: 'Bearer ' + token } })
                .then(response => {
                    if (response.data.success)
                        setAccept(prevValue=>!prevValue)
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }

    const getStakes = () => {

        axios.get('/user/getStakes', { headers: { Authorization: 'Bearer ' + token } })
            .then(response => {
                if (response.data.success) {
                    setStakes(response.data.data)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    const hideModal = () => {
        setOpen(false);
    }

    const showFancy = (runner) => {

        axios.get(`/user/getFancyBook/${params.matchId}/${runner}`, { headers: { Authorization: 'Bearer ' + token } })
            .then(response => {

                if (response.data.success) {
                    response.data.data = response.data.data.map(run=>{
                        run.net_profit-=response.data.commission;
                        return run;
                    })
                    setBook(response.data.data)
                    setMarketIdForMaxUpdate(null)
                    setOpen(true)
                }
            })
    }

    const showMaxUpdate = (id) => {

        setMarketIdForMaxUpdate(id)
        setBook(null)
        setOpen(true)
    }

    const updateMaximum = () => {

        const payload = {
            eventId: params.matchId,
            marketId: marketIdForMaxUpdate,
            max: max
        }
        axios.put('/user/setInplayMax/', payload, { headers: { Authorization: 'Bearer ' + token } })
            .then(response => {

                if (response.data.success) {
                    setMarketIdForMaxUpdate(true)
                    setBook(null)
                    setOpen(false)
                    getMarkets()
                }
            })
    }

    const handleClick = (id) => {
        if(isBetting) return
        setActiveId(id)
    }

    const getMatchInfo = () => {
        axios.get('/user/matchInfo/' + params.matchId)
            .then(response => {
                if (response.data.success) {
                    setMatchInfo(response.data.data[0])
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    const getMarkets = () => {

        axios.get('/user/getMarketsByMatch/' + params.matchId, { headers: { Authorization: 'Bearer ' + token } })
            .then(response => {

                if (response.data.success) {

                    if(!response.data.data.markets.length){
                        window.location.href = '/dashboard'
                    }

                    const allMarkets = response.data.data.markets;
                    if(usertype === '5'){
                        setAccept(response.data.data.any_odds);
                        setCode(response.data.data.code)
                    }

                    allMarkets.forEach(market => {
                        if(market.name === 'Match Odds' || market.name === 'Winner')
                            setMarketInfoForAnalysis({id: market.id, name: market.name})
                    });

                    setMarkets(allMarkets)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
    
    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible' && socket && !socket.connected) {
            reloadPage(); // Refresh the page when the tab becomes visible and socket is disconnected
        }
    };

    const refreshOnReconnect = () => {
        const handleOnline = () => {
            reloadPage(); // Refresh the page when back online
        };
    
        const handleOffline = () => {
            setIsOnline(false);
        };
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    }

    useEffect(()=>{

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    },[])

    useEffect(() => {
        refreshOnReconnect()
    },[isOnline])

    useEffect(() => {
        getMatchInfo()
        getMarkets()

        if (usertype !== "5") {
            const timer = usertype === "1" ? 10000 : 30000
            const intervalId = setInterval(getMarkets, timer);
            return () => clearInterval(intervalId); // cleanup on unmount
        }
    }, [params])

    useEffect(() => {
        if (usertype === '5') {
            getStakes()
        }
    }, [props.stakeChanged, usertype])

    useEffect(()=>{
        marketInfoForAnalysis.id && props.changeMarket(marketInfoForAnalysis.id , params.matchId, marketInfoForAnalysis.name)
    },[marketInfoForAnalysis.id])

    let allMarket = <BetSpinner />, scoreCard = null, inModal = null;

    if (book) {
        inModal = <TableContainer componenet={Paper} style={{ maxHeight: '400px' }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>Run</TableCell>
                        <TableCell align='center'>Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inModal = Array.isArray(book) && book.length ? book.map(fancy => {
                        return <TableRow
                            key={fancy.user_rate}
                            style={{ backgroundColor: fancy.color === 'red' ? "pink" : 'skyblue' }}>
                            <TableCell align='center'>{fancy.user_rate}</TableCell>
                            <TableCell align='center' style={{ color: fancy.net_profit < 0 ? 'red' : 'green' }}>{formatCoins(fancy.net_profit >= 0 ? fancy.net_profit : -fancy.net_profit)}</TableCell>
                        </TableRow>
                    }) : <TableRow>
                        <TableCell align='center' colSpan={2}>No book found.</TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
    } else if (marketIdForMaxUpdate) {
        inModal = <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <FormControl>
                <InputLabel htmlFor="max">Inplay max</InputLabel>
                <Input
                    id="max"
                    name='max'
                    type='number'
                    onChange={(event) => {
                        setMax(event.target.value)
                    }}
                    value={max}
                />
            </FormControl>
            <Button
                variant="contained"
                onClick={() => {
                    updateMaximum()
                }}>Set</Button>
        </div>
    }

    if (markets && marketInfoForAnalysis.id) {
        allMarket = markets.map(market => {
            return <MarketTableNew
                oddsMarketId={marketInfoForAnalysis.id}
                marketId={market.id}
                key={market.id}
                name={market.name}
                adv_max={market.adv_max}
                max={market.max}
                min={market.min}
                odds={market.odds}
                startTime={market.marketStartTime}
                eventId={params.matchId}
                changeMarket={props.changeMarket}
                changeFlag={props.changeFlag}
                handleClick={handleClick}
                showMaxUpdate={showMaxUpdate}
                activeId={activeId}
                sport={matchInfo?.sport}
                eventName={matchInfo?.name}
                accept={accept}
                stakes={stakes}
                toggleAcceptAnyOdds={toggleAcceptAnyOdds}
                runners={market.runners}
                updateBook={updateBook}
                code={code}
                socket={socket}
                inplay={inplay}
                setInplay={setInplay}
                ref={ref}
            />
        }).filter(market => market !== null)
    }

    if (markets?.length && !marketInfoForAnalysis.id) {
        allMarket = null
    }

    if (matchInfo && matchInfo?.sport) {
        scoreCard = <ScoreCard
            eventId={params.matchId}
            eventName={matchInfo?.name}
            openDate={matchInfo?.openDate}
            eventTypeId={matchInfo.sport}
        />
    }

    if (matchInfo && matchInfo.sport === '4') {
        return (
            <div style={{padding: '0 4px'}}>
                <Modal
                    open={open}
                    onClose={hideModal}
                >
                    {inModal}
                </Modal>
                {scoreCard}
                {allMarket}
                <Fancy
                    handleClick={handleClick}
                    activeId={activeId}
                    matchId={params.matchId}
                    eventName={matchInfo.name}
                    showModal={showFancy}
                    sport={matchInfo.sport}
                    stakes={stakes}
                    socket={socket}
                    code={code}
                    isTestMatch={markets?.[0].runners?.length === 3}
                />
            </div>
        );
    }
    else {
        return (
            <div>
                {scoreCard}
                {allMarket}
            </div>
        );
    }
})

export default Market